import axios from "axios"

export const getStories = (url, responseHandler, errorHandler) => {
  axios({
    method: "GET",
    headers: { "content-type": "application/json" },
    url,
  })
    .then(response => {
      if (responseHandler) responseHandler(response.data)
    })
    .catch(error => {
      if (errorHandler) errorHandler(error)
    })
}

export const getStorieData = async id => {
  try {
    const response = await axios(`${process.env.GATSBY_API_DETAIL_URL}?id=${id}`)
    return response.data
  } catch (error) {
    return error
  }
}

export const searchStories = async (search, page, pageSize = 10) => {
  try {
    // const response = await axios(`http://threetest.local/getFind.php?busqueda=${search}&page=${page}&page_size=${pageSize}`)
    const response = await axios(`${process.env.GATSBY_API_SEARCH_URL}?busqueda=${search}&page=${page}&page_size=${pageSize}`)
    return response.data
  } catch (error) {
    return error
  }
}
