// Params: ['blend-color=0000FF', 'fit=crop&w=300&h=100']

const getImgixUrl = (url, paramsArray = []) => {
  const cloudfrontDomain = "https://ddjepxwa1mzzq.cloudfront.net/"
  const imgxDomain = "https://jeepstories.imgix.net/"
  const joinParams = paramsArray.join("?")
  const params = paramsArray.length > 0 ? `?${joinParams}&auto=compress,format` : "?auto=compress,format"

  let sourceUrl = url.replace(cloudfrontDomain, imgxDomain)
  sourceUrl += `${params}`

  return sourceUrl
}

export default getImgixUrl
