import * as React from "react"

function SvgPromoted(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 211 44" xmlSpace="preserve" {...props}>
      <path d="M201 10.3L211 1v33.7L201 44V10.3z" fillRule="evenodd" clipRule="evenodd" fill="#c5a356" />
      <path d="M0 0h211v34H0l7-17L0 0z" fillRule="evenodd" clipRule="evenodd" fill="#ecc662" />
      <path fill="#151624" d="M43.7 15l-6.1-.5-2.4-5.6-2.4 5.6-6.1.5 4.7 4-1.5 6 5.3-3.1 5.3 3.1-1.4-6 4.6-4zM55.7 22.4V18h2.7v4.4h1.9V12.3h-1.9v4h-2.7v-4h-1.9v10.1h1.9zM65 22.4V12.3h-1.9v10.1H65zM73.5 14.6c-.3-1.4-1.4-2.5-3.1-2.5-2 0-3 1.1-3 2.8 0 2.8 4.3 2.9 4.3 4.8 0 .8-.5 1.2-1.2 1.2-.6 0-1.3-.4-1.5-1.7l-1.9.4c.5 2 1.7 2.8 3.3 2.8 2 0 3.2-1.1 3.2-2.9 0-3.2-4.3-3.3-4.3-4.8 0-.7.4-1.1 1.1-1.1.7 0 1.1.4 1.4 1.4l1.7-.4zM75.4 12.3V14h2.3v8.4h1.9V14h2.3v-1.7h-6.5zM83.5 19.1c0 2.5 1.3 3.4 3.2 3.4 1.9 0 3.2-.9 3.2-3.4v-3.6c0-2.5-1.3-3.4-3.2-3.4-1.9 0-3.2.9-3.2 3.4v3.6zm2-3.8c0-1.1.5-1.4 1.3-1.4s1.3.3 1.3 1.4v4.1c0 1.1-.5 1.4-1.3 1.4s-1.3-.3-1.3-1.4v-4.1zM98.8 22.4l-1.9-4.5c1.1-.4 1.5-1.3 1.5-2.6 0-1.9-1.1-3-3.4-3h-2.8v10.1h1.9v-4.1h.9l1.7 4.1h2.1zm-4.7-8.5h1c.9 0 1.4.4 1.4 1.4 0 1-.5 1.4-1.4 1.4h-1v-2.8zM102.7 22.4V12.3h-1.9v10.1h1.9zM108.3 14.5l1 4.1h-1.9l.9-4.1zm-1.8 7.9l.5-2.1h2.7l.5 2.1h1.8l-2.4-10.1h-2.5l-2.5 10.1h1.9zM118.4 22.4h2.7c2.2 0 3.4-1 3.5-3.5v-3.1c-.1-2.5-1.3-3.5-3.5-3.5h-2.7v10.1zm1.9-8.4h.7c1.2 0 1.7.6 1.7 2v2.7c0 1.5-.6 2-1.7 2h-.7V14zM132.3 22.4v-1.7h-3.5V18h2.6v-1.7h-2.6V14h3.4v-1.7h-5.3v10.1h5.4zM140.5 14.6c-.3-1.4-1.4-2.5-3.1-2.5-2 0-3 1.1-3 2.8 0 2.8 4.3 2.9 4.3 4.8 0 .8-.5 1.2-1.2 1.2-.6 0-1.3-.4-1.5-1.7l-1.9.4c.5 2 1.7 2.8 3.3 2.8 2 0 3.2-1.1 3.2-2.9 0-3.2-4.3-3.3-4.3-4.8 0-.7.4-1.1 1.1-1.1.7 0 1.1.4 1.4 1.4l1.7-.4zM142.4 12.3V14h2.3v8.4h1.9V14h2.3v-1.7h-6.5zM153.2 14.5l1 4.1h-1.9l.9-4.1zm-1.8 7.9l.5-2.1h2.7l.5 2.1h1.8l-2.4-10.1H152l-2.5 10.1h1.9zM160.5 15.3c0-1.1.5-1.4 1.3-1.4s1.3.3 1.3 1.4v.3h1.9c0-2.5-1.3-3.4-3.2-3.4s-3.2.9-3.2 3.4v3.6c0 2.5 1.3 3.4 3.2 3.4s3.2-.9 3.2-3.4v-.4h-2v.7c0 1.1-.5 1.4-1.3 1.4s-1.3-.3-1.3-1.4v-4.2zM170.2 14.5l1 4.1h-1.9l.9-4.1zm-1.9 7.9l.5-2.1h2.7l.5 2.1h1.8l-2.4-10.1H169l-2.5 10.1h1.8zM175.7 22.4h2.7c2.2 0 3.4-1 3.5-3.5v-3.1c-.1-2.5-1.3-3.5-3.5-3.5h-2.7v10.1zm1.9-8.4h.7c1.2 0 1.7.6 1.7 2v2.7c0 1.5-.6 2-1.7 2h-.7V14zM187.2 14.5l1 4.1h-1.9l.9-4.1zm-1.9 7.9l.5-2.1h2.7l.5 2.1h1.8l-2.4-10.1H186l-2.5 10.1h1.8z" />
    </svg>
  )
}

export default SvgPromoted
