import * as React from "react"

function SvgPromotedSmall(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 34" {...props}>
      <path d="M0 0h45v34H0l7-17L0 0z" fill="#ecc662" />
      <path d="M34.7 15l-6.1-.5-2.4-5.6-2.4 5.6-6.1.5 4.7 4-1.5 6 5.3-3.1 5.3 3.1-1.4-6 4.6-4z" fill="#151624" />
    </svg>
  )
}

export default SvgPromotedSmall
